import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import React, { useEffect } from "react";
import { ROUTES } from "../../lib/consts";
import "./Completed.scss";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";


import { useTranslation } from "react-i18next";
import store from "../../store/store";

const Completed = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <MainLayout className="completed-page">
        <div className="congratulation-container">
          <img
            src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/congrats.gif`}

            alt="Congratulation"
            className="congratulation-image"
          />

          <p className="congrats-title">Congratulations!</p>
          <p className="congrats-text">You have successfully participated in the Bumper draw.
          </p>

          {/* <img src={footer} className="footer-img" alt="footer" /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default Completed;
